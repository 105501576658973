<template>
  <div id="bufferStock" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
        <div class="row m-0 p-4">
          <div class="col-12 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Buffer Stock</p>
          </div>
        </div>
        <div class="row m-0 pl-4 pb-4">
          <div class="col-12 mx-0 mb-3 mb-xl-0">
            <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
              จำนวน &nbsp;
              <span class="text-main">
                {{ new Intl.NumberFormat().format(totalRows) }}
              </span>
              <span class="ml-2">รายการ</span>
            </p>
            <div v-if="isLoading" class="col-12 col-xl-12 m-0 p-0 text-center">
              <div class="p-xl-5 m-xl-5">
                <b-spinner variant="info" small type="grow"></b-spinner>
                <b-spinner variant="info" small type="grow"></b-spinner>
                <b-spinner variant="info" small type="grow"></b-spinner>
              </div>
            </div>
            <div v-else>
              <b-table
                hover
                outlined
                show-empty
                class="form-control-sm font-0-7s"
                :items="items"
                :empty-text="'ไม่พบข้อมูล / No Data'"
                :fields="fields"
                head-variant="light"
                :current-page="page_num"
                :per-page="page_size"
              >
                <template #cell(stkPerCenSdate)="row">
                  <div>
                    <input
                      class="form-control form-control-sm text-center"
                      type="date"
                      v-model="row.item.stkPerCenSdate"
                    />
                  </div>
                </template>
                <template #cell(stkPerCenEdate)="row">
                  <div>
                    <input
                      class="form-control form-control-sm text-center"
                      type="date"
                      v-model="row.item.stkPerCenEdate"
                    />
                  </div>
                </template>
                <template #cell(stkPerCen)="row">
                  <div>
                    <input
                      class="form-control form-control-sm text-center"
                      type="number"
                      min="0"
                      max="100"
                      v-model="row.item.stkPerCen"
                    />
                  </div>
                </template>
                <template #cell(action)="row">
                  <span
                    type="button"
                    class="text-bnb font-1-2s bold"
                    @click="updateBufferStock(row.item)"
                    >บันทึก
                  </span>
                </template>
              </b-table>
            </div>

            <div class="col-12">
              <div class="row justify-content-end">
                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                  <select class="custom-select" v-model="page_size">
                    <option
                      v-for="(e, i) in page_size_list"
                      :key="i"
                      :value="e"
                    >
                      {{ e }}
                    </option>
                  </select>
                </div>
                <div class="col-6 col-xl-3">
                  <b-pagination
                    v-model="page_num"
                    :total-rows="totalRows"
                    :per-page="page_size"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BufferStockIndex",
  data() {
    return {
      isLoading: false,
      page_size: 10,
      page_num: 1,
      page_size_list: [10, 20, 50, 100],
      totalRows: 0,
      items: [],
      fields: [
        { key: "saleSource", label: "Sale Source", class: "text-center" },
        { key: "provider", label: "Provider", class: "text-center" },
        {
          key: "stkPerCenSdate",
          label: "Stock Start Date",
          class: "text-center",
        },
        {
          key: "stkPerCenEdate",
          label: "Stock End Date",
          class: "text-center",
        },
        {
          key: "stkPerCen",
          label: "Percent Stock(100%)",
          class: "text-center",
        },
        { key: "action", label: "Action", class: "text-center" },
      ],
    };
  },
  async mounted() {
    await this.getBufferStock();
  },
  methods: {
    async getBufferStock() {
      try {
        this.isLoading = true;
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `market-place/getBufferStock`,
          {},
          1
        );
        this.items = getAPI.data;
        this.totalRows = getAPI.data.length;
        this.isLoading = false;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async updateBufferStock(item) {
      try {
        this.isLoading = true;
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `market-place/updateBufferStock`,
          item,
          1
        );
        this.isLoading = false;
        this.$serviceMain.showSuccessAlert(this, getAPI.message);
        await this.getBufferStock();
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>
